
/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./components/NavigationHandler').default} NavigationHandler
 * @typedef {import('./components/CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';
import CommonMethods from './components/CommonMethods';
import { ApiClient } from './components/ApiClient';
import Dialog from './components/Dialog';
import Slider from './components/Slider';
import Confetti from 'canvas-confetti';
import AbortablePromise from './components/AbortablePromise';

class Tokenhunt {
    getClassName () { return 'TokenHunt'; }

    constructor () {
        /** @type {console} */
        this.logger = ClassLogger(this, true);

        if (
            new Date(window.tokenhuntConfig.enddate) < new Date() ||
            new Date(window.tokenhuntConfig.startdate) > new Date()
        ) {
            this.logger.log('Out of promotion date range');
            return;
        }

        if (window.navigationHandler) {
            this.addNavigationHandlerEvents();
        } else {
            window.addEventListener('navigationhandler:init', () => this.addNavigationHandlerEvents());
        }
        this.contextkey = window.antenne.config.contextkey || '';

        this.lastRequest = null;
    }

    addNavigationHandlerEvents () {
        window.navigationHandler.on('ready', () => this.init());
        window.navigationHandler.on('render', () => {
            if (this.lastRequest !== null) {
                if (this.lastRequest instanceof AbortablePromise) {
                    this.lastRequest.abort();
                } else {
                    this.logger.error('last request is not an AbortablePromise');
                }
            }
            // hide (all) currently visible tokens
            document.querySelectorAll('.c-tokenhunt-token').forEach(token => { this.hideToken(token); });
            this.handlePageview();
        });
    }

    init () {
        this.logger.log('Initializing TokenHunt dependencies');
        this.commonMethods = new CommonMethods(window.antenneEmitter);
        this.apiClient = new ApiClient();
        this.dialogService = new Dialog(
            this.commonMethods,
            window.navigationHandler,
            window.antenneEmitter,
            new Slider(this.commonMethods, window.navigationHandler),
        );

        this.handlePageview();
    }

    shouldShowInvitation () {
        if (localStorage.getItem('tokenhunt-invitation-skipped') === '1') {
            this.logger.log('User has skipped invitation');
            return false;
        }
        if (Math.random() < 0.4) {
            return true;
        }
        return false;
    }

    async handlePageview () {
        if (
            new Date(window.tokenhuntConfig.enddate) < new Date()
        ) {
            this.logger.log('Out of promotion date range');
            return;
        }

        this.logger.log('Handling pageview');

        if (window.location.pathname.startsWith(window.tokenhuntConfig.landingpage)) {
            this.logger.log('Skipping becuase on promotion page');
            return;
        }

        if (await window.antenneUser.isLoggedIn() === false) {
            this.logger.log('User is not authenticated');
            if (this.shouldShowInvitation()) {
                this.logger.log('Should show invitation');
                this.lastRequest = this.apiClient.get('/tokenhunt/view');
                const response = await this.lastRequest;
                if (response && response.template) {
                    this.renderToken(response);
                }
            }
            return;
        }

        // check `nbf` from local storage - return if not yet reached
        if ((this.getStoredToken().nbf || 0) > (Date.now() / 1000)) {
            this.logger.log('nbf not reached', {
                nbf: new Date(this.getStoredToken().nbf * 1000),
                token: this.getStoredToken(),
            });
            return;
        }

        const req = this.fetchToken();
        // if (req instanceof AbortablePromise === false) {
        //     this.logger.error('last request (fetchToken) is not abortable', req);
        // }
        const response = await req;
        if (response && response.win && response.win === true && response.token) {
            // show token
            this.renderToken(response);
        } else if (response && response.template) {
            if (this.shouldShowInvitation()) {
                this.renderToken(response);
            } else {
                this.logger.log('Skipped rendering because of probability');
            }
        }
    }

    getStoredToken () {
        return JSON.parse(localStorage.getItem('tokenhunt') || '{}');
    }

    async renderToken (response) {
        this.logger.log('Rendering token', response);
        if (response.template) {
            const tokenElement = this.commonMethods.markupToElement(response.template);
            tokenElement.addEventListener('click', async (e) => {
                if (response.win) {
                    await this.redeemToken(tokenElement, response);
                } else {
                    this.hideToken(tokenElement);
                }
            });

            document.body.append(tokenElement);
            const randomPos = Math.floor(Math.random() * 100);
            tokenElement.style.setProperty('--dynmaicLeft', `${randomPos}%`);

            // user has only 10sec time to click on the token (if it is a winner-token)
            if (response.win) {
                setTimeout(() => this.hideToken(tokenElement), 15000);
            } else {
                // handle click on "skip" (X) button
                tokenElement.querySelector('button[data-skip]').addEventListener('click', e => {
                    this.hideToken(tokenElement);
                    localStorage.setItem('tokenhunt-invitation-skipped', '1');
                });
            }
        }
        if (response.dialog) {
            const dialog = this.commonMethods.markupToElement(response.dialog);
            window.antenne.templates = window.antenne.templates || {};
            window.antenne.templates.tokenhunt = (id) => {
                let template = dialog.outerHTML;
                template = template.replace(/\$\{id\}/g, id);
                return template;
            };
        }
    }

    async fetchToken () {
        const promise = this.apiClient.post(
            'tokenhunt/view',
            {
                token: this.getStoredToken().token || null,
            },
        );

        promise.then((result) => {
            if (result && result.token) {
                localStorage.setItem('tokenhunt', JSON.stringify(result));
            }
            return result;
        });

        promise.catch(error => this.logger.warn('Failed to fetch token', error));

        this.lastRequest = promise;
        return promise;
    }

    /**
     * @param {object}response
     */
    async redeemToken (tokenElement, response) {
        // removed stored token early
        localStorage.removeItem('tokenhunt');
        const token = response.token;
        const fireworkPromise = this.tokenFirework(tokenElement, 1800);
        try {
            const redeemPromise = this.apiClient.post(
                'tokenhunt/redeem',
                {
                    token,
                },
            );

            // wait minimum 2s and maximum until redeem request finished
            await Promise.all([fireworkPromise, redeemPromise]);

            Confetti.reset();

            const dialog = this.dialogService.renderDialog(response.dialog, true);
            dialog.show();
            this.dialogFirework();
            dialog.on('hide', () => {
                if (this.dialogInterval) {
                    clearInterval(this.dialogInterval);
                }
            });
        } catch (error) {
            this.logger.error('Failed to redeem token', error);
        } finally {
            this.hideToken(tokenElement);
        }
    }

    hideToken (tokenElement) {
        if (!tokenElement || !document.body.contains(tokenElement)) {
            // in case it has alrady been removed
            return;
        }
        this.logger.log('About to hide token', tokenElement);
        tokenElement.addEventListener('animationend', (event) => {
            if (event.animationName === 'slide-out-bottom') {
                tokenElement.remove();
            }
        });
        tokenElement.classList.add('slide-out');
    }

    dialogFirework () {
        const duration = 10 * 1000;
        const animationEnd = Date.now() + duration;
        const defaults = {
            startVelocity: 15,
            gravity: 0.1,
            spread: 360,
            ticks: 60,
            zIndex: 102,
            disableForReducedMotion: true,
            colors: [
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-secondary'),
            ],
        };

        if (this.commonMethods.getTemplateVariation() === 'oldieantenne') {
            defaults.colors = ['#cd007b', '#e2001a', '#ee7f00', '#1fa22e', '#00519e', '#f6c900'];
        }

        function randomInRange (min, max) {
            return Math.random() * (max - min) + min;
        }

        this.dialogInterval = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(this.dialogInterval);
            }

            const particleCount = 50 * (timeLeft / duration);

            // since particles fall down, start a bit higher than random
            Confetti(
                Object.assign({},
                    defaults,
                    {
                        particleCount,
                        zIndex: 102,
                        origin: {
                            x: randomInRange(0.1, 0.3),
                            y: Math.random() - 0.2,
                        },
                    }));
            Confetti(
                Object.assign({},
                    defaults,
                    {
                        particleCount,
                        zIndex: 102,
                        origin: {
                            x: randomInRange(0.7, 0.9),
                            y: Math.random() - 0.2,
                        },
                    }));
        }, 250);
    }

    tokenFirework (target, duration) {
        return new Promise((resolve) => {
            if (!target) return resolve();
            const targetRect = target.getBoundingClientRect();
            const animationEnd = Date.now() + duration;
            const defaults = {
                startVelocity: 8,
                gravity: 0.1,
                spread: 360,
                ticks: 60,
                zIndex: 50,
                disableForReducedMotion: true,
                colors: [
                    getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                    getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-primary'),
                    getComputedStyle(document.documentElement).getPropertyValue('--antenne-color-secondary'),
                ],
            };

            if (this.commonMethods.getTemplateVariation() === 'oldieantenne') {
                defaults.colors = ['#cd007b', '#e2001a', '#ee7f00', '#1fa22e', '#00519e', '#f6c900'];
            }

            const fire = function (targetRect) {
                const timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    resolve();
                    clearInterval(interval);
                    return;
                }

                const particleCount = 40;

                // since particles fall down, start a bit higher than random
                console.log('Firework targetRect ' + (targetRect.right / window.innerWidth));
                Confetti(
                    Object.assign({},
                        defaults,
                        {
                            particleCount,
                            origin: {
                                x: (targetRect.right - targetRect.width / 2) / window.innerWidth,
                                y: (targetRect.top + targetRect.height / 2) / window.innerHeight,
                            },
                        }));
            };

            fire(targetRect);
            const interval = setInterval(fire, 600, targetRect);
        });
    }
}
new Tokenhunt();
